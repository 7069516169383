
:root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }
  
  /* GENERAL */
  
  
  /* GRID */
  
  .calendar-header .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .calendar-header .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .calendar-header .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .calendar-header .col-center {
    justify-content: center;
    text-align: center;
  }
  
  .calendar-header .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  
  /* Calendar */
  
  .calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
  }
  
  .calendar .header {
    text-transform: uppercase;
    /* padding: .75em 0; */
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .cell {
    position: relative;
    height: 5.25em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    /* cursor: pointer; */
    background: var(--neutral-color);
    transition: 0.25s ease-out;
    font-size: 1.5em;
    border-bottom: 1px solid ;
  }
  
  .calendar .body .cell:hover {
    /* background: var(--bg-color); */
    transition: 0.2s ease-out;

    border-left: 5px solid transparent;
    border-color: var(--border);
    border-image-slice: 1;
  }
  
  .calendar .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1;
    opacity: .8;
  }

  /* .calendar .body .today {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
    border-image-slice: 1;
  } */


  .calendar .body .row {
    border-bottom: 1px solid var(--border-color);
  }
  
  /* .calendar .body .row:last-child {
    border-bottom: none;
  } */
  
  .calendar .body .cell:last-child {
    border-right: none;
  }
  
  .calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: 0.75em;
    right: 0.75em;
    font-weight: 700;
    opacity: 0.6;
  }
  
  .calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }
  
  .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0.35;
    font-size: 4em;
    position: absolute;
    top: -0.1em;
    right: -0.0em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }
  
  .calendar .check{
    position: absolute;
    bottom: 0;
    right: 1px;
  }
  
  .calendar .body .cell.today .bg {
    color: #ff1a79;
    opacity: 0.35;
  }
/*   
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  } */

  .cell .pay{
    font-size: medium !important;
    opacity: 0.7
  }
  .cell select{
    border: 0 !important;
    /* background-color: transparent !important; */
    font-weight: 800;
    
  }

  @media only screen and (min-width: 601px) {
    .calendar .body .col {
        flex-grow: 0;
        flex-basis: calc(100% / 3);
        width: calc(100% / 3);
      } 
  }

  @media only screen and (max-width: 600px) {
    .calendar .body .col {
        flex-grow: 0;
        flex-basis: calc(100% / 1);
        width: calc(100% / 1);
      } 
  }